<template>
  <div>
    <div class="flex-parent-view">
      <h1>Die Magenta Welt erleben</h1>
      <div class="layout-grid">
        <template v-for="(item, i) in mainSlides">
          <template v-if="item.name && item.title && dynamicVisibility(item)">
            <div class="child hidden-item" v-if="mainSlides.length === 4 && i === 1" :key="'hiddenSlide-' + i">
              <div class="child-items"></div>
            </div>
            <router-link class="child" :class="{ 'speed-check': item.name === 'speed-check' || item.name === 'streaming-calculator' }" :key="'allSlides-' + i" :to="{ path: item.name }">
              <div class="child-items">
                <h2>
                  <span class="d-flex algin-items-center mr-4 mb-1" v-if="item.name === 'speed-check'">
                    <img src="@/assets/images/Icon-SpeedRechner-white.svg" alt="" />
                  </span>
                  <span v-html="item.title"></span>
                </h2>
              </div>
            </router-link>
          </template>
        </template>
      </div>
      <div class="checkpanel">
        <div class="rowInput">
          <p :class="{ active: !type }">pk</p>
          <label class="toggleCheckbox">
            <input v-model="type" type="checkbox" class="hiddenInput"/>
            <span></span>
          </label>
          <p :class="{ active: type }">gk</p>
        </div>
      </div>
      <div class="main-bottom-links" v-if="links">
        <template v-for="(item, i) in links" >
          <a :key="'a-link-' + i" :href="item.link" :target="item.is_external ? '_blank' : '_self'">{{ item.title }}</a>
          <span v-if="i != (Object.keys(links).length)" :key="'a-link-divider-' + i" class="menu-item-divider"></span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'

const portraitDevice = window.matchMedia('(max-width: 1080px)')

export default {
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['page', 'mainSlides']),
    filteredSlides () {
      return this.mainSlides.filter(item => {
        return item.type === this.type
      })
    }
  },
  data () {
    return {
      type: true,
      links: null
      // slides: [
      //   {
      //     title: '5G',
      //     type: false,
      //     name: 'slidesone'
      //   },
      //   {
      //     title: 'Familie',
      //     type: false,
      //     name: 'slidesfive'
      //   },
      //   {
      //     title: 'Heim-vernetzung',
      //     type: false,
      //     name: 'slidestwo'
      //   },
      //   {
      //     title: 'MagentaTV',
      //     type: false,
      //     name: 'slidessix'
      //   },
      //   {
      //     title: 'Smarte Produkte',
      //     type: false,
      //     name: 'slidesfour'
      //   },
      //   {
      //     title: 'MagentaEINS',
      //     type: false,
      //     name: 'slideseight'
      //   },
      //   {
      //     title: 'Home-Office',
      //     type: true,
      //     name: 'slidesthree'
      //   },
      //   {
      //     title: 'Schutz',
      //     type: true,
      //     name: ''
      //   },
      //   {
      //     title: 'Digitale Kasse',
      //     type: true,
      //     name: ''
      //   },
      //   {
      //     title: 'Online Präsenz',
      //     type: true,
      //     name: ''
      //   },
      //   {
      //     title: 'Speed Up',
      //     type: false,
      //     name: 'slidesten'
      //   }
      // ]
    }
  },
  methods: {
    ...mapActions('slides', ['searchPage', 'clearSlide']),
    dynamicVisibility (item) {
      return item.show_in_large && portraitDevice.matches
    }
  },
  created () {
    // self.searchMainSlides()
  },
  mounted () {
    this.type = this.$route.query.type === '2'
    let self = this
    this.$nextTick(() => {
      this.searchPage('').then(resp => {
        if (resp) {
          self.links = resp.data
        }
      })
    })
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
